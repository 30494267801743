import React from "react";
import Stars from "../components/stars";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';

const Review = ({title, name, thumbtack, date, children}) => (
  <Card className='cg-reviewCard'>
    <Card.Body>
      <Card.Title>{title}</Card.Title>
      <Card.Subtitle>
        <Stars className='rating'/>
      </Card.Subtitle>
      <Card.Text>
          <FontAwesomeIcon icon={faQuoteLeft} className='quoteChar'/>
          <span className='quoteText'> {children} </span>
          <span className='attribution'>
            ~&nbsp;{name}
            {!!thumbtack && /* TODO move URL to config */
              <small> via <a href="https://www.thumbtack.com/ny/utica/roofing/mlb-contracting-llc/service/251824188443034876" className='cg-linkDark'>Thumbtack</a></small>
            }
          </span> 
          {date && <span className='ratingDate'>{moment(date, "M/D/YYYY").fromNow()}</span>}
      </Card.Text>
    </Card.Body>
  </Card>
);

export default Review;
import React from "react";

class Hero extends React.Component {
  //constructor(props) {
  //    super(props);
  //}

  render() {

    return (
        <div className='abovefold' >
          <h1><span className='pretitle'>{this.props.pretitle}</span>{this.props.title}</h1>
          <div className='subtitle'>{this.props.subtitle}</div>
          <div>
            {this.props.children}
          </div>
        </div>
    );
  }
}

export default Hero;
import React from 'react'
import Header from "../components/header";
import Hero from "../components/hero";
import { Button } from "react-bootstrap";

export default ({ title, subtitle, pretitle, children }) => (
    <div style={{margin:0, padding: '0 0 2em 0', textAlign:'center', minHeight:'80vh'}}>
      <Header/>
      <Hero
        pretitle={pretitle}
        title={title}
        subtitle={subtitle}
        >
        <br/>
        <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
      </Hero>
      {children}
    </div>
    )